<template>
  <div>
    <h1>Sorry!</h1>
    <h3>Some error occurred while trying to load the page</h3>
    <h4>{{ errorMessage ? errorMessage : '' }} </h4>
  </div>
</template>
<script>

export default {
  props: {
    errorMessage: {
      type: String,
      required: false
    }
  }
};
</script>
